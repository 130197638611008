<template>
  <div class="container">
    <div class="header flex-between-start">
      <div class="handle">
        <div class="title">商家列表</div>
        <el-button type="primary" @click="handleEdit()" v-permission="['新增商家']">新增商家</el-button>
        <!-- <el-button @click="handleUpload">导入商家</el-button> -->
        <!-- <el-button @click="batchManage">批量管理</el-button> -->
      </div>
      <div class="search">
        <el-select v-model="category_id" placeholder="请选择行业" clearable>
          <el-option v-for="item in cateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-input v-model="name" type="text" placeholder="请输入商家名称" clearable></el-input>
        <el-button @click="pageEmit(1)">搜索</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table
      ref="multipleTableRef"
      :data="tableData.items"
      v-loading="loading"
      @selection-change="handleSelectionChange"
      height="700"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column label="商家logo" width="130">
        <template #default="scope">
          <img class="logo-img" :src="scope.row.logo_img?.url || defaultLogo" alt="商家logo" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="商家名称"></el-table-column>
      <el-table-column prop="phone" label="联系电话" width="150"></el-table-column>
      <el-table-column prop="mchnt_cd" label="商家号"></el-table-column>
      <!-- <el-table-column prop="pos_tmn" label="商家终端号"></el-table-column> -->
      <el-table-column prop="cate" label="所属行业">
        <template #default="scope">
          {{ scope.row.category?.name }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="商家地址"></el-table-column>
      <el-table-column label="商家状态" width="90">
        <template #default="scope">
          <el-tag size="small" :type="['info', 'success'][scope.row.status]">{{
            ['禁用', '启用'][scope.row.status]
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="230">
        <template #default="scope">
          <el-button
            plain
            size="small"
            type="primary"
            @click="handleEdit(scope.row)"
            v-permission="['编辑商家', '商家详情']"
            >查看</el-button
          >
          <el-button
            plain
            size="small"
            type="warning"
            @click="handleStatus(scope.row.id)"
            v-permission="['设置商家状态']"
            >{{ ['启用', '禁用'][scope.row.status] }}</el-button
          >
          <el-button plain size="small" type="danger" @click="handleDelete(scope.row.id)" v-permission="['删除商家']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <page
      @page-event="pageEmit"
      :current-page="pageParam.currentPage"
      :total="tableData.total"
      :page-size="tableData.count"
    ></page>

    <!-- 修改商家行业对话框 -->
    <el-dialog
      title="批量管理"
      :model-value="dialogVisible"
      :before-close="handleClose"
      width="20%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <el-form :rules="rules" ref="form" :model="merForm">
        <el-form-item label="所属行业" prop="cateId">
          <el-select v-model="merForm.cateId" placeholder="请选择行业" clearable>
            <el-option v-for="item in cateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleConfirm">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import merchantModel from '@/model/merchant'
import { ElMessage } from 'element-plus/lib/components'
import commonHandle from '@/hooks/useCommon'
import defaultLogo from '@/assets/image/default-logo.png'

export default {
  setup() {
    const router = useRouter()
    const store = useStore()
    const { state } = store
    // const loading = ref(false)
    const cateList = ref([])
    const category_id = ref()
    const name = ref()
    // const tableData = ref({})
    const multipleSelection = ref([])
    const dialogVisible = ref(false)
    const form = ref(null)
    const merForm = reactive({ cateId: '' })
    const rules = {
      cateId: [{ message: '请选择行业', trigger: 'blur', required: true }],
    }

    // 获取商家列表
    const getData = async pageQuery => {
      try {
        loading.value = true
        let res = await merchantModel.getMerchantList({
          page: pageQuery?.page || pageParam.currentPage - 1,
          count: pageQuery?.count || pageParam.count,
          category_id: category_id.value,
          name: name.value,
        })
        tableData.value = res
        return res
      } finally {
        loading.value = false
      }
    }

    // 获取所有行业分类
    const getMerCateListAll = async () => {
      let res = await merchantModel.getMerCateListAll()
      cateList.value = res
    }

    // 添加/编辑商家
    const handleEdit = item => {
      if (item) {
        methods.handleMutation(item)
      }
      router.push({ path: '/merchant/update', query: { id: item?.id } })
    }

    // 修改商家状态
    const handleStatus = async id => {
      loading.value = true
      let res
      try {
        res = await merchantModel.editMerchantStatus(id)
        ElMessage.success(res.message)
      } finally {
        loading.value = false
        getData()
      }
    }

    // 选择
    const handleSelectionChange = val => {
      console.log(val)
      multipleSelection.value = val
    }

    // 批量管理
    const batchManage = () => {
      if (!multipleSelection.value.length) return ElMessage.warning('请选择商家')
      dialogVisible.value = true
    }

    // 确认修改
    const handleConfirm = () => {
      form.value.validate(async valid => {
        if (valid) {
          const merList = multipleSelection.value.map(item => item.id)
          let res
          try {
            loading.value = true
            res = await merchantModel.batchEditMerCate({
              cateId: merForm.cateId,
              merList,
            })
            ElMessage.success(res.message)
          } finally {
            form.value.resetFields()
            loading.value = false
            dialogVisible.value = false
          }
        }
      })
    }

    // 导入商家
    const handleUpload = () => {}

    const methods = {
      // 处理commit
      handleMutation: data => {
        store.commit('SET_MERCHANT_INFO', data)
      },
    }

    // 删除
    const deleteFun = async id => {
      let res
      res = await merchantModel.deleteMerchant(id)
      return res
    }

    const { tableData, loading, pageParam, handleDelete, pageEmit } = commonHandle(getData, deleteFun)

    // 关闭弹窗
    const handleClose = done => {
      form.value.resetFields()
      dialogVisible.value = false
      if (typeof done === 'function') done()
    }

    onMounted(() => {
      getData()
      getMerCateListAll()
    })

    return {
      loading,
      cateList,
      pageParam,
      category_id,
      name,
      tableData,
      multipleSelection,
      dialogVisible,
      form,
      merForm,
      rules,
      state,
      store,
      defaultLogo,
      getData,
      handleEdit,
      handleDelete,
      handleStatus,
      handleSelectionChange,
      batchManage,
      handleClose,
      handleConfirm,
      pageEmit,
      methods,
      handleUpload,
      getMerCateListAll,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';
.logo-img {
  width: 80px;
  height: 80px;
}
</style>
